/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios, { type AxiosResponse } from 'axios';
import type { EcosystemVehicle, VehiclePrice } from '~/types';

interface Customer {
  fullname: string
  phone: string
  email: string
  accepted_terms_and_cons: boolean
}
interface ContactLeadParams {
  customer: Pick<Customer, 'fullname' | 'phone' | 'accepted_terms_and_cons'>
}
interface SubscriptionLeadParams {
  start: string
  duration: string | number
  station_data: {
    id: string | number
    type: string
  }
  km: number
  vehicle_id: string | number
  customer: Customer
}

interface PaginatedResponse<T> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export function useApi () {
  const { API_URL: baseURL } = useRuntimeConfig().public;

  const axios = Axios.create({
    baseURL,
    timeout: 15000,
    headers: {
      accept: 'application/json',
    },
  });

  axios.interceptors.response.use((res) => {
    return res;
  }, (error) => {
    if (!error.response) {
      const Sentry = useNuxtApp().$sentry as any;
      Sentry.captureException('[Error response is not present]: CORS issue? ->' + ` ${JSON.stringify(error)}`);
    }

    return Promise.reject(error);
  });

  const getStation = (params?: string): Promise<AxiosResponse> => {
    const path = params ? `/stations/${params}` : '/stations/';

    return axios.get(path);
  };

  const getVehicles = (params?: string): Promise<AxiosResponse<PaginatedResponse<EcosystemVehicle>>> => {
    const path = params ? `/vehicles/${params}` : '/vehicles/';

    return axios.get(path);
  };

  const getVehicle = (id: string | number): Promise<AxiosResponse<EcosystemVehicle>> => {
    return axios.get(`/vehicles/${id}/`);
  };

  const getVehiclePrice = (payload: {id: string, month: string | number, km: string | number}): Promise<AxiosResponse<VehiclePrice>> => {
    const { id, month, km } = payload;
    const builtEndpoint = `/vehicles/${id}/price/${[month, km].join('/')}/`;
    return axios.get(builtEndpoint);
  };

  const getVehicleFeatures = (id: string | number): Promise<AxiosResponse<any>> => {
    return axios.get(`/features/subscription/vehicle/${id}/`);
  };

  const sendSubscriptionLead = (params: SubscriptionLeadParams): Promise<AxiosResponse> => {
    return axios.post('/subscription/', params);
  };

  const sendContactLead = (params: ContactLeadParams): Promise<AxiosResponse> => {
    return axios.post('/contact/', params.customer);
  };

  const getFeaturedCars = (): Promise<AxiosResponse<PaginatedResponse<EcosystemVehicle>>> => {
    return axios.get('/vehicles/popular/');
  };

  const getAvailableSubscriptionMonths = (): Promise<AxiosResponse<{results: number[], count: number}>> => {
    return axios.get('/months/');
  };

  return {
    getStation,
    getVehicles,
    getVehicle,
    sendContactLead,
    sendSubscriptionLead,
    getVehicleFeatures,
    getFeaturedCars,
    getAvailableSubscriptionMonths,
    getVehiclePrice,
  };
}
